@tailwind base;
@tailwind components;
@tailwind utilities;
/*@tailwind variants;*/

@layer base {
  :root {
    /*
    *
    * This all are the rgb values which are used inside the
    * tailwind config file and custom plugins css file
    *
    */

    /*
    * primary color
    */
    --color-accent: 0, 159, 127;

    /*
    * Hover state color of primary color
    */
    --color-accent-hover: 1, 147, 118;

    /*
    * shade of primary color
    */
    --color-accent-300: 153, 217, 204;

    /*
    * shade of primary color
    */
    --color-accent-400: 77, 188, 165;

    /*
    * shade of primary color
    */
    --color-accent-500: 0, 159, 127;

    /*
    * shade of primary color
    */
    --color-accent-600: 0, 143, 114;

    /*
    * shade of primary color
    */
    --color-accent-700: 0, 119, 95;

    /*
    * light color
    */
    --color-light: 255, 255, 255;

    /*
    * dark color
    */
    --color-dark: 0, 0, 0;

    --color-error: 255, 0, 0;

    /*
    * Black muted color
    */
    --color-muted-black: 17, 17, 17;

    /*
    *
    * TEXT colors
    *
    */

    /*
    * text color used for body/base text -> gray-500
    */
    --text-base: 107, 114, 128;

    /*
    * dark base text color  -> gray-600
    */
    --text-base-dark: 75, 85, 99;

    /*
    * muted text color -> gray-400
    */
    --text-muted: 156, 163, 175;

    /*
    * light muted text color - gray-300
    */
    --text-muted-light: 209, 213, 219;

    /*
    * text color used for heading text -> gray-700
    */
    --text-sub-heading: 55, 65, 81;

    /*
    * text color used for heading text -> gray-800
    */
    --text-heading: 31, 41, 55;

    /*
    * text color used for heading text -> gray-900
    */
    --text-bolder: 31, 41, 55;

    /*
    *
    * border colors
    *
    */

    /*
    * border color 50
    */
    --color-border-50: 249, 250, 251;

    /*
    * border color 100
    */
    --color-border-100: 243, 244, 246;

    /*
    * border color 200
    */
    --color-border-200: 229, 231, 235;

    /*
    * border base color which is 300
    */
    --color-border-base: 209, 213, 219;

    /*
    * border color 400
    */
    --color-border-400: 156, 163, 175;

    /*
    *
    * Tailwind default gray color override
    *
    */

    /*
    * gray-50
    */
    --color-gray-50: 249, 250, 251;

    /*
    * gray-100
    */
    --color-gray-100: 243, 244, 246;

    /*
    * gray-200
    */
    --color-gray-200: 229, 231, 235;

    /*
    * gray-300
    */
    --color-gray-300: 209, 213, 219;

    /*
    * gray-400
    */
    --color-gray-400: 156, 163, 175;

    /*
    * gray-500
    */
    --color-gray-500: 107, 114, 128;

    /*
    * gray-600
    */
    --color-gray-600: 75, 85, 99;

    /*
    * gray-700
    */
    --color-gray-700: 55, 65, 81;

    /*
    * gray-800
    */
    --color-gray-800: 31, 41, 55;

    /*
    * gray-900
    */
    --color-gray-900: 31, 41, 55;
  }

  html {
    -webkit-tap-highlight-color: transparent;
    font-size: 18px;
  }

  body {
    @apply font-body;
    font-size: 18px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading;
  }

  a {
    @apply no-underline;
  }
}
@import './custom-plugins.css';

@media all and (max-width: 1280px) {
  .hero-image__container {
    height: calc(1.33 * 100vw);
    max-height: calc(1.33* 100vw);
    min-height: calc(1.33 * 100vw);
    margin-bottom: 30px !important;
    /*margin-top: -30px;*/
  }
  .hero-image__container span {
    /*height: 85vh !important;*/
    /*height: calc(100vh - 120px) !important;*/
    height: calc(1.33 * 100vw);
    max-height: calc(1.33 * 100vw);
    min-height: calc(1.33 * 100vw);
  }
}

@media all and (min-width: 1281px) {
  .hero-image__container {
    height: calc(100vh);
    max-height: calc(100vh);
    min-height: calc(100vh);
    margin-bottom: 30px !important;
    /*margin-top: -30px;*/
  }
  .hero-image__container span {
    /*height: 85vh !important;*/
    /*height: calc(100vh - 120px) !important;*/
    height: calc(100vh);
    max-height: calc(100vh);
    min-height: calc(100vh);
  }
}

/*@media all and (min-width: 701px) {*/
/*  .hero-image__container {*/
/*    height: calc(100vh - 120px);*/
/*    max-height: calc(100vh - 120px);*/
/*    min-height: calc(100vh - 120px);*/
/*    margin-bottom: 30px !important;*/
/*    margin-top: -50px;*/
/*  }*/
/*  .hero-image__container span {*/
/*    !*height: 85vh !important;*!*/
/*    !*height: calc(100vh - 120px) !important;*!*/
/*    height: calc(100vh - 120px);*/
/*    max-height: calc(100vh - 120px);*/
/*    min-height: calc(100vh - 120px);*/
/*  }*/
/*}*/

@media all and (max-width: 800px) {
.hero-image__container span {
  height: 85vh !important;
  height: calc(100vh - 104px) !important;
}
}
.hero-image__container span {
  /*height: 85vh !important;*/
  /*height: calc(100vh - 104px) !important;*/
}

.hero-image__container span img {
  object-fit: fill !important;
}

.min-h-62 {
  min-height: 62px;
  margin-bottom: 16px;
}

.align-center {
  align-items: center;
}


.cart-item_active {}

.cart-item_expired {
  background-color: rgba(255, 0, 0, .1);
}

.cart-item_overstock {
  /*border: 1px solid pink;*/
  background-color: rgba(255, 0, 0, .05);
}

.image__cart__container {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;
}

.image__cart {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
  filter: none;
  background-size: contain;
  background-image: none;
  background-position: 0% 0%;
}

.payment-button {
  padding-left: 10px;
}
.payment-button input[type='image'] {
  max-width: 128px;
}

.tree-item-active {
  font-weight: 900;
  color:  #009F7F;
}

.border-color-green {
  border-color:  #009F7F;
}

.w-half {
  width: 50%;
}
